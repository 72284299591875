import React from 'react';

import Message from '@app/components/Common/message/Message';
import MessageList from '@app/components/Common/message/MessageList';
import { useMessages } from '@app/modules/MessageContext';
import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';
import { PfDropdown, PfImage } from '@profabric/react-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 18rem;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

const MessagesDropdown = () => {
  const [t] = useTranslation();
  const wsCtx = useWs();
  const userCTX = useUser();
  const messageCTX = useMessages();
  const calculateMarginLeft = () => {
    const totalWindows = messageCTX.messageWindow.listOfMessageWindowDisplayed.length;
    const marginLeft = totalWindows === 0 ? `calc(100vw - 350px)` : `calc(100vw - 350px - ${totalWindows * 280}px)`;
    return marginLeft;
  };
  return (
    <StyledDropdown hideArrow>
      <div slot="button">
        <i className="far fa-comments" />
        <span className="badge badge-danger navbar-badge"></span>
      </div>
      <div slot="menu">
        {wsCtx?.connectedUser
          ?.filter((e: any) => e.ID !== userCTX.authenticationInfos.user?.Id)
          .map((e: any, i: number) => {
            return (
              <div key={'user' + i}>
                <div
                  className="dropdown-item"
                  onClick={() => {
                    const id = e.UserId;
                    const newWindow = {
                      id: id, // ou utilisez un ID unique
                      component: Message,
                      data: { recipient: e.ID, messages: [] },
                      actions: { hideSubForm: () => messageCTX.hideMessageWindow(id) },
                      x: calculateMarginLeft(),
                      translatedTitle: e.Name,
                      y: 'calc(100vh - 450px)',
                    };

                    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
                    messageCTX.setMessageWindow((prevWindow) => ({
                      ...prevWindow,
                      listOfMessageWindowDisplayed: [...prevWindow.listOfMessageWindowDisplayed, newWindow],
                    }));
                  }}
                >
                  <div className="media">
                    <PfImage src={e.Avatar} alt="User Avatar" width={50} rounded className="mr-2" />
                    <div className="media-body">
                      <h3 className="dropdown-item-title">
                        {e.Name}
                        <span className="float-right text-sm text-danger">
                          <i className="fas fa-star" />
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="dropdown-divider" />
              </div>
            );
          })}
      </div>
    </StyledDropdown>
  );
};

export default MessagesDropdown;
