import React from 'react';

import { useMessages } from '@app/modules/MessageContext';
import { useUser } from '@app/modules/user/UserContext';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface messageList {
  sender: string;
  recipient: string;
  message: string;
  date: string;
}

interface props {
  recipient: string;
}
const MessageList: React.FC<props> = (props) => {
  const userCTX = useUser();
  const messageCTX = useMessages();
  return (
    <div
      style={{
        width: '250px',
        height: '300px',
        border: '2px solid silver',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      {messageCTX.messages &&
        messageCTX?.messages[props.recipient]?.map((e, i) => {
          return (
            <div
              key={i}
              className={'message' + (userCTX.authenticationInfos.user?.Id === e.SenderId ? ' message-self' : '')}
            >
              <div className="message-text">{e.Message}</div>
            </div>
          );
        })}
    </div>
  );
};

export default MessageList;
