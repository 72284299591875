import React, { useState } from 'react';

import TypeDisponibility from '@app/components/Common/popup/typeDisponibility';
import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { useUser } from '@app/modules/user/UserContext';
import { ZoneEvent } from '@app/types/Planning/zoneEvent';
import { shortDateToLocaleString } from '@app/utils/dates';
import { formatDate } from '@mobiscroll/react';
import { MbscCalendarDayData } from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types.public';
import moment from 'moment';
import { ContextMenu } from 'primereact/contextmenu';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@mui/material';

const CustomDay = ({
  args,
  zoneEvents,
  date,
}: {
  args: MbscCalendarDayData;
  zoneEvents: ZoneEvent[] | null; // Replace 'YourEventType' with your actual event type
  date: Date;
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const userCTX = useUser();
  const subFormCTX = useSubForm();
  const userLocale = userCTX.authenticationInfos.language;
  const options = { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' } as Intl.DateTimeFormatOptions;
  const [targets, setTargets] = useState<any>([]);
  const eventInDate = zoneEvents?.find(
    (e) =>
      moment(e.Dates.DateStart as string).dayOfYear() <= moment(args.date).dayOfYear() &&
      moment(e.Dates.DateEnd as string).dayOfYear() >= moment(args.date).dayOfYear(),
  );
  const handleDragStart = (e: any) => {
    setStartDate(e.target.innerText);
    e.target.classList.add('dragging');
  };

  const handleDragOver = (e: any) => {
    e.preventDefault(); // Nécessaire pour permettre le drop
    e.target.classList.add('dragging');
    setTargets([...targets, e]);
  };

  const handleDrop = (e: any) => {
    setEndDate(e.target.innerText);
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: TypeDisponibility, // Remplacez par le composant que vous souhaitez afficher
      data: { startDate, endDate }, // Les données que vous souhaitez transmettre au composant
      actions: {
        hideSubForm: () => {
          subFormCTX.hideSubForm(id);
          Array.from(document.getElementsByClassName('dragging')).forEach((element) => {
            element.className = '';
          });
        },
      },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      translatedTitle: 'Disponibilité par type',
      x: 'calc(50% - 200px)',
      y: '20vh',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };
  const filtersCTX = useFilters();
  const planningCTX = usePlanning();
  const [t] = useTranslation();
  const formattedDate = date.toLocaleDateString(userLocale, options);
  const cm = subFormCTX.cmDayRef;
  const items = [
    { label: 'Ajouter une nouvelle zone tarifaire', icon: 'fa-solid fa-file-circle-plus' },
    { label: 'Tableau des zones', icon: 'fa-regular fa-calendar' },
    { label: 'Affectation des chambres', icon: 'fa-solid fa-bed' },
    { label: 'Tarifs distribués - prix', icon: 'fa-solid fa-sliders' },
    { label: 'Tarifs distribués - modification en masse', icon: 'fa-solid fa-sliders' },
  ];
  return (
    <>
      <ContextMenu style={{ width: '250px', fontSize: '12pt' }} model={items} ref={cm} />

      <Tooltip
        title={
          eventInDate !== undefined && (
            <>
              {' '}
              {formattedDate}
              <br />
              {eventInDate.Title && (
                <>
                  {eventInDate.Title}
                  <br />
                </>
              )}
              {eventInDate.Description && (
                <>
                  {eventInDate.Description}
                  <br />
                </>
              )}
              {eventInDate.Condition}
              <br />
              {shortDateToLocaleString(
                moment(eventInDate.Dates.DateStart).toDate(),
                userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
              ) +
                ' - ' +
                shortDateToLocaleString(
                  moment(eventInDate.Dates.DateEnd).toDate(),
                  userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                )}
            </>
          )
        }
      >
        <div
          //     onContextMenu={(e) => (cm.current !== null ? cm.current.show(e) : '')}
          className={'D' + new Date(date).getUTCDate().toString()}
          style={{
            backgroundColor: eventInDate !== undefined ? (eventInDate.Background ?? '') : '#c0c0c0',
            color: eventInDate !== undefined ? (eventInDate.Foreground ?? '') : '',
            fontWeight: 'bold',
            fontSize: '12pt',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              height: filtersCTX.filters.planningFilters.ForceOneDay ? '48px' : '36px',
              alignContent: 'center',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
            //    draggable="true"
            onDragStart={(event) => handleDragStart(event)}
            onDragOver={(event) => handleDragOver(event)}
            onDrop={(event) => handleDrop(event)}
            onClick={() => {
              userCTX.setForceLoading(true).then(() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  planningFilters: {
                    ...prevState.planningFilters,
                    DateStart: new Date(date).getTime(),
                    DateEnd: moment(new Date(date)).add(21, 'day').toDate().getTime(),
                  },
                }));

                planningCTX.getPlanning(filtersCTX.filters.planningFilters);

                planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
                userCTX.setForceLoading(false);
              });
            }}
          >
            {formatDate('DDD DD', date, {
              dayNamesShort: Object.values(t('calendar.dayNamesShort', { returnObjects: true })),
            })}
          </div>
        </div>
      </Tooltip>
    </>
  );
};

export default CustomDay;
