/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { usePrevision } from '@app/modules/prevision/PrevisionContext';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { CumulData, PrevisionData } from '@app/types/types';
import { formatPrice } from '@app/utils/formatCurrency';
import { roundOneDecimal } from '@app/utils/numbers';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { DataTable, DataTableSelectionSingleChangeEvent, DataTableValue } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PrevisionDatatable: React.FC = () => {
  const filtersCTX = useFilters();

  const previsionFilters = filtersCTX.filters.previsionFilters;

  const previsionCTX = usePrevision();

  const hotels = previsionCTX.GridGlobal;
  const dataDetail = previsionCTX.GridCumul;
  const userCTX = useUser();
  const authData = userCTX.authenticationInfos;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const [t] = useTranslation();
  const [selectedField, setSelectedField] = useState('');
  const [selectedDataField, setSelectedDataField] = useState('');
  const [selectedDetail, setSelectedDetail] = useState<DataTableValue | null>(null);
  const [selectedDataDetail, setSelectedDataDetail] = useState<DataTableValue | null>(null);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const cumulMode = [
    { name: t('prevision.label.day'), code: 0 },
    { name: t('prevision.label.Country'), code: 1 },
    { name: t('prevision.label.Segment'), code: 2 },
    { name: t('prevision.label.Origine'), code: 3 },
    { name: t('prevision.label.numberRooms'), code: 4 },
    { name: t('prevision.label.Type'), code: 5 },
    { name: t('prevision.label.Hebergement'), code: 6 },
    { name: t('prevision.label.CodeTarif'), code: 7 },
    { name: t('prevision.label.Distributeur'), code: 8 },
    { name: t('prevision.label.TypeStandard'), code: 9 },
    { name: t('prevision.label.NomTypeStandard'), code: 10 },
  ];

  useEffect(() => {
    if (previsionFilters.hasBeenModified) {
      previsionCTX.setGridCumul(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previsionFilters.hasBeenModified]);
  useEffect(() => {
    if (filtersCTX.filters.previsionFilters.needReload === true && isTech !== undefined) {
      previsionCTX.getPrevisionData({
        previsionFilters,
        params: {
          isTech,
          selectedHotel: userCTX.authenticationInfos.selectedHotel,
        },
      });
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        previsionFilters: {
          ...prevState.previsionFilters,
          needReload: false,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    authData.selectedHotel,
    filtersCTX,
    filtersCTX.filters.previsionFilters.needReload,
    isTech,
    previsionCTX,
    previsionFilters,
  ]);
  useEffect(() => {
    if (previsionFilters.ForecastType !== null) {
      setSelectedField(cumulMode.find((e) => e.code === previsionFilters.ForecastType)?.name || 'Jour');
      switch (cumulMode.find((e) => e.code === previsionFilters.ForecastType)?.code || 0) {
        case 0:
          setSelectedDataField('Day');
          break;
        case 1:
          setSelectedDataField('PostalCountry');
          break;
        case 2:
          setSelectedDataField('Segment');
          break;
        case 3:
          setSelectedDataField('Origin');
          break;
        case 4:
          setSelectedDataField('Room');
          break;
        case 5:
          setSelectedDataField('RoomType');
          break;
        case 6:
          setSelectedDataField('Accomodation');
          break;
        case 7:
          setSelectedDataField('RateCode');
          break;
        case 8:
          setSelectedDataField('Distributor');
          break;
        case 9:
          setSelectedDataField('StandardRoomType');
          break;
        case 10:
          setSelectedDataField('StandardRoomTypeName');
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previsionFilters.ForecastType]);

  const handleRowSelection = (e: DataTableSelectionSingleChangeEvent<PrevisionData>) => {
    const selectedData = e.value;
    setSelectedDetail(selectedData);
  };

  const handleRowDetailSelection = (e: DataTableSelectionSingleChangeEvent<CumulData>) => {
    const selectedData = e.value;
    setSelectedDataDetail(selectedData);
  };

  const rowClass = (data: DataTableValue) => {
    return {
      'total-PrevisionLine': data.hotel === 'TOTAL',
    };
  };

  const formatBody = (data: PrevisionData, i: ColumnBodyOptions) => {
    const key = i.field;
    const cellValue = String(data[key as keyof PrevisionData]);
    return (
      <div style={{ textAlign: 'right', marginRight: '10px', padding: 0 }}>
        {formatPrice(parseFloat(cellValue), null)}
      </div>
    );
  };

  const formatPrevisionBody = (data: CumulData, i: ColumnBodyOptions) => {
    const key = i.field;
    const cellValue = String(data[key as keyof CumulData]);
    if (i.field === 'Segment' || i.field === 'Origin') {
      if (data.Depth === 0) {
        return <b>{data[i.field]}</b>;
      } else {
        return data[i.field];
      }
    } else if (i.field === 'Day') {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' } as Intl.DateTimeFormatOptions;

      // Format the date based on the user's locale
      const userLocale = authData.language; // Use the user's browser language as a default

      return <b>{new Date(cellValue).toLocaleDateString(userLocale, options)}</b>;
    } else if (i.field === 'PostalCountry') {
      return <b>{cellValue}</b>;
    } else if (i.field === 'Room') {
      return <b>{data[i.field]}</b>;
    } else if (i.field === 'RoomType') {
      return <b>{cellValue}</b>;
    } else if (i.field === 'Accomodation') {
      return <b>{data[i.field]}</b>;
    } else if (i.field === 'RateCode') {
      return <b>{cellValue}</b>;
    } else if (i.field === 'Distributor') {
      return <b>{cellValue}</b>;
    } else if (i.field === 'RoomsSoldPercent') {
      return <>{cellValue}</>;
    } else if (i.field === 'TurnOverPercent') {
      return <>{cellValue}</>;
    } else if (i.field === 'StandardRoomType') {
      return <>{cellValue}</>;
    } else if (i.field === 'StandardRoomTypeName') {
      return <>{cellValue}</>;
    } else {
      return (
        <div style={{ textAlign: 'right', marginRight: '10px', padding: 0 }}>
          {formatPrice(parseFloat(cellValue), null)}
        </div>
      );
    }
  };
  const formatPercent = (data: CumulData, i: ColumnBodyOptions) => {
    const key = i.field;
    const cellValue = String(data[key as keyof CumulData]);
    return (
      <>
        <ProgressBar
          style={{ height: '5px', borderRadius: 0, margin: 0, padding: 0, top: '-3px', color: 'orange' }}
          showValue={false}
          value={parseFloat(cellValue) * 5}
        ></ProgressBar>
        <p style={{ padding: 0, margin: 0 }}>{roundOneDecimal(parseFloat(cellValue))} %</p>
      </>
    );
  };
  return (
    <>
      {' '}
      {hotels !== null && (
        <div className={`small-box bg-gradient-light`}>
          <div className="small-box" style={{ borderRadius: '8px' }}>
            <>
              <DataTable
                value={hotels}
                stripedRows
                className="normalDT previsionDT"
                scrollable
                rowHover
                rowClassName={rowClass}
                selectionMode="single"
                selection={selectedDetail as DataTableValue}
                onSelectionChange={(e: DataTableSelectionSingleChangeEvent<PrevisionData>) => handleRowSelection(e)}
              >
                <Column
                  field="Hotel"
                  header={t('prevision.label.hotel')}
                  style={{
                    width: '30%',
                    backgroundColor: darkMode ? colors.colorGreyAdminLTE : colors.colorGrey100,
                    color: darkMode ? colors.colorPrimaryContrastText : colors.colorGreyAdminLTE,
                  }}
                  className="previsionDTName"
                ></Column>
                <Column
                  field="RoomsSoldQty"
                  header={<div title="Nombre de chambres occupées"> {t('prevision.label.rooms')}</div>}
                  style={{ width: '10%' }}
                ></Column>
                <Column
                  field="TurnOver"
                  body={formatBody}
                  header={<div title="Chiffre d'affaires"> {t('prevision.label.CA')}</div>}
                  style={{ width: '10%' }}
                ></Column>
                <Column
                  field="AccomodationTurnOver"
                  body={formatBody}
                  header={<div title="Chiffre d'affaires par hebergement"> {t('prevision.label.CAH')}</div>}
                  style={{ width: '10%' }}
                ></Column>
                <Column
                  field="OccupancyRate"
                  header={<div title="Taux d'occupation"> {t('prevision.label.TO')}</div>}
                  style={{ width: '10%' }}
                ></Column>
                <Column
                  field="Adr"
                  body={formatBody}
                  header={<div title="Prix Moyen"> {t('prevision.label.PM')}</div>}
                  style={{ width: '10%' }}
                ></Column>
                <Column
                  field="RevPor"
                  body={formatBody}
                  header={
                    <div
                      title="REVenue Per Occupied Room
Revenu global par chambre vendue. Il s’agit du chiffre d’affaires total moyen par chambre vendue.

A la différence du Prix Moyen (ADR), cette indicateur prend tout le chiffre d’affaires généré par une chambre vendue.

Formule de calcul :

Chiffre d'affaires / nombre de chambres vendues"
                    >
                      {t('prevision.label.PMH')}
                    </div>
                  }
                  style={{ width: '10%' }}
                ></Column>
                <Column
                  field="RevPar"
                  body={formatBody}
                  header={
                    <div
                      title="REVenue Per Available Room
Revenu par chambre disponible. Il s'agit du Chiffres d'affaires hébergement rapporté à la chambre disponible (vendable)

Formule de calcul :

Chiffre d'affaires hébergement / nombre de chambres dans l'hôtel

Il en existe plusieurs variantes, on a souvent tendance à calculer le RevPar à partir du chiffre d'affaires global. Dans ce cas de figure il s'agit en réalité d'un autre indicateur appelé TREVPAR."
                    >
                      {t('prevision.label.RevPar')}
                    </div>
                  }
                  style={{ width: '10%' }}
                ></Column>
              </DataTable>
            </>
          </div>
        </div>
      )}
      {dataDetail !== null && !filtersCTX.filters.previsionFilters.hasBeenModified && (
        <div className={`small-box bg-gradient-light`}>
          <div className="small-box" style={{ borderRadius: '8px' }}>
            <>
              <DataTable
                id="DetailPrevision"
                rowHover
                selectionMode="single"
                selection={selectedDataDetail as DataTableValue}
                onSelectionChange={(e: DataTableSelectionSingleChangeEvent<CumulData>) => handleRowDetailSelection(e)}
                value={dataDetail}
                stripedRows
                className="normalDT previsionDT"
                scrollable
              >
                {
                  <Column
                    field={selectedDataField}
                    body={formatPrevisionBody}
                    sortable={
                      selectedDataField !== 'Segment' &&
                      selectedDataField !== 'Origin' &&
                      selectedDataField !== 'Distributor'
                        ? true
                        : false
                    }
                    header={selectedField}
                    style={{ width: '18.5%', textAlign: 'left', paddingLeft: '20px' }}
                  ></Column>
                }
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="RoomsSoldQty"
                  header={<div title="Nombre de chambres occupées"> {t('prevision.label.rooms')}</div>}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="RoomsSoldPercent"
                  body={formatPercent}
                  header={<div title="Pourcentages de chambres occupées"> {t('prevision.label.roomsP')}</div>}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="TurnOver"
                  body={formatPrevisionBody}
                  header={<div title="Chiffre d'affaires"> {t('prevision.label.CA')}</div>}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="TurnOverPercent"
                  body={formatPercent}
                  header={t('prevision.label.CAP')}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="AccomodationTurnOver"
                  body={formatPrevisionBody}
                  header={<div title="Chiffre d'affaires par hebergement"> {t('prevision.label.CAH')}</div>}
                  style={{ width: '7.5%', textAlign: 'right', paddingRight: '5px' }}
                  bodyStyle={{ paddingRight: '10px !important', marginRight: '10px' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="RevPor"
                  header={
                    <div
                      title="REVenue Per Occupied Room
Revenu global par chambre vendue. Il s’agit du chiffre d’affaires total moyen par chambre vendue.

A la différence du Prix Moyen (ADR), cette indicateur prend tout le chiffre d’affaires généré par une chambre vendue.

Formule de calcul :

Chiffre d'affaires / nombre de chambres vendues"
                    >
                      {t('prevision.label.PMH')}
                    </div>
                  }
                  style={{ width: '7.5%' }}
                  body={formatPrevisionBody}
                ></Column>

                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="Adr"
                  header={<div title="Prix Moyen"> {t('prevision.label.PM')}</div>}
                  body={formatPrevisionBody}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="StaysQty"
                  header={<div title="Nombre de séjours"> {t('prevision.label.Stay')}</div>}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="Pax"
                  header={<div title="Nombre de personnes"> {t('prevision.label.Pers')}</div>}
                  style={{ width: '7.5%' }}
                ></Column>
                <Column
                  sortable={
                    selectedDataField !== 'Segment' &&
                    selectedDataField !== 'Origin' &&
                    selectedDataField !== 'Distributor'
                      ? true
                      : false
                  }
                  field="FrequencyIndex"
                  header={
                    <div
                      title=" Indice de Fréquentation

L'indice de fréquentation fait partie des KPI (Key Performances Indicator) d'un hôtel. Il permet de mesurer l'occupation moyenne de chaque chambre vendue

Formule de calcul : 

Nombre de personnes / Nombre de chambres vendues

L'IF est intéressant en gestion analytique, car l'hôtel a un nombre chambres défini et donc un nombre de lits (une capacité maximale d'hébergement), le fait de d'approcher son occupation de cette capacité maximale tend à maximiser le profit de l'hôtel.

Exemple sur les chambres doubles de l'hôtel il est bon que l'IF soit proche de 2 (les chambres doubles permettant une occupation 2 personnes).

Dans le cas d'un hôtel possédant des restaurants, bar et autres centres de profits, le fait que l'IF soit élevé permettra, en théorie, également de maximiser le profit autre qu'hébergement.
"
                    >
                      {t('prevision.label.IF')}
                    </div>
                  }
                  style={{ width: '7.5%' }}
                ></Column>
              </DataTable>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default PrevisionDatatable;
