import React, { createContext, ReactNode, useContext, useState } from 'react';

import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

// Define the type for your params

// Define the interface for ParamsContextState
interface BookingContextState {
  BookingSummary: BookingSummary | null;
  getBookingSummary: (params: bookingSummaryParams) => Promise<BookingSummary | undefined>;
  setBookingSummary: (BookingSummary: BookingSummary) => void;
}

interface bookingSummaryParams {
  params: TechAndHotelParams;
  idBooking: string | number;
}
// Create a context to hold your params with default values
export const BookingContext = createContext<BookingContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type BookingProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const BookingProvider: React.FC<BookingProviderProps> = (props) => {
  const [BookingSummary, setBookingSummary] = useState<BookingSummary | null>(null);

  const getBookingSummary = async (params: bookingSummaryParams) => {
    try {
      // Effectuez ici
      // Exemple d'utilisation de fetch :
      const response = await axios.get('Bookings/Summary/' + params.idBooking);

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setBookingSummary(data.Data as BookingSummary);
      return data.Data as BookingSummary;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <BookingContext.Provider
      value={{
        BookingSummary,
        getBookingSummary,
        setBookingSummary,
      }}
    >
      {props.children}
    </BookingContext.Provider>
  );
};

// Create a custom hook to access the params
export function useBookings(): BookingContextState {
  const bookingContext = useContext(BookingContext);
  if (bookingContext === undefined) {
    throw new Error('useBooking must be used within a BookingProvider');
  }
  return bookingContext;
}
