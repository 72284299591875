import React, { useCallback, useEffect, useState } from 'react';

import Filters from '@app/components/Common/filter/Filter';
import MainCouranteFilters from '@app/components/Common/filter/MainCouranteFilter';
import PerformanceFilters from '@app/components/Common/filter/PerformanceFilters';
import PickupFilters from '@app/components/Common/filter/PickupFilters';
import PlanningFilters from '@app/components/Common/filter/PlanningFilters';
import PrevisionFilters from '@app/components/Common/filter/PrevisionFilters';
import RepartitionFilters from '@app/components/Common/filter/RepartitionFilters';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useFilters } from '@app/modules/filter/filterContext';
import LanguagesDropdown from '@app/modules/main/header/languages-dropdown/LanguagesDropdown';
import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';

import { storage } from '@app/store/localstorage/localStorage';
import { toggleSidebarMenu } from '@app/store/reducers/ui';
import { RootState } from '@app/store/store';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import moment from 'moment-timezone';
import { Dropdown } from 'primereact/dropdown';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from '@services/axios/Axios';

import UserDropdown from './user-dropdown/UserDropdown';
import MessagesDropdown from './messages-dropdown/MessagesDropdown';

const Header = () => {
  const dispatch = useDispatch();
  const navbarVariant = useSelector((state: RootState) => state.ui.navbarVariant);
  const headerBorder = useSelector((state: RootState) => state.ui.headerBorder);
  const location = useLocation();
  const userCTX = useUser();
  const wsCTX = useWs();

  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const filtersCTX = useFilters();
  const hotelList = userCTX.authenticationInfos.listHotel;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dataFactoryCTX = useDataFactory();
  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);

  useEffect(() => {
    if (axios.defaults.headers.common['IdProperty'] === undefined) {
      return;
    }
    if (
      userCTX &&
      userCTX.authenticationInfos &&
      userCTX.authenticationInfos.selectedHotel &&
      userCTX.authenticationInfos.selectedHotel !== undefined &&
      userCTX.authenticationInfos.selectedHotel?.IdHotel &&
      userCTX.authenticationInfos.axiosIdProperty !== 0 &&
      userCTX.authenticationInfos.axiosIdProperty !== undefined &&
      axios.defaults.headers.common['IdProperty'] &&
      axios.defaults.headers.common['IdProperty'] === userCTX.authenticationInfos.selectedHotel?.IdHotel
    ) {
      // userCTX.getRoomsByIdHotel(userCTX.authenticationInfos.selectedHotel.IdHotel);

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        dashboardFilters: {
          ...prevState.dashboardFilters,
          DateStart: moment(selectedHotel?.CurrentPlanningDate).toDate() ?? 0,
          hasBeenModified: true,
        },
      }));

      dataFactoryCTX
        .getDataDictionary({
          IdHotel: userCTX.authenticationInfos.axiosIdProperty?.toString() ?? '',
          Culture: userCTX.authenticationInfos.user?.Culture ?? 'Fr-fr',
        })
        .then(() => {
          if (userCTX.authenticationInfos.selectedHotel?.IdHotel) {
            dataFactoryCTX.getPMSSettings(userCTX.authenticationInfos.selectedHotel.IdHotel);
            userCTX
              .getInstallationMenu()
              .then(() => navigate('/hotel/' + userCTX.authenticationInfos.selectedHotel?.IdHotel));
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userCTX.authenticationInfos.selectedHotel?.IdHotel,
    userCTX.authenticationInfos.axiosIdProperty,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    axios.defaults.headers.common['IdProperty'],
    selectedHotel?.CurrentPlanningDate,
  ]);

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`;
    if (headerBorder) {
      classes = `${classes} border-bottom-0`;
    }
    return classes;
  }, [navbarVariant, headerBorder]);

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav">
        <li className="nav-item" style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <button onClick={handleToggleMenuSidebar} type="button" className="nav-link">
            <i className="fas fa-bars" />
          </button>
          {location.pathname !== '/maincourante' &&
            location.pathname !== '/pickup' &&
            location.pathname !== '/performance' &&
            location.pathname !== '/repartition' &&
            location.pathname !== '/prevision' && (
              <Dropdown
                id="HotelDropDown"
                filterBy="IdHotel,Name"
                filterMatchMode="contains"
                disabled={hotelList?.length === 1}
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX
                    .setUserAsync((prevState) => ({
                      ...prevState,
                      axiosIdProperty: e.value.IdHotel,
                      selectedHotel: e.value,
                    }))
                    .then(() => {
                      if (isTech) {
                        userCTX
                          .getHotelVisibility(
                            e.value.IdHotel as number,
                            userCTX.authenticationInfos.user?.Id as string,
                            isTech,
                          )
                          .then((res) => {
                            if (res) {
                              userCTX.setUser((prevState) => ({
                                ...prevState,
                                selectedHotel: e.value ?? '',
                              }));
                              filtersCTX.setFilters((prevState) => ({
                                ...prevState,
                                dashboardFilters: {
                                  ...prevState.dashboardFilters,
                                  needReload: true,
                                },
                              }));
                              storage.setParam('selectedHotel', JSON.stringify(e.value));
                            }
                          });
                      } else {
                        userCTX.setUser((prevState) => ({
                          ...prevState,
                          selectedHotel: e.value ?? '',
                        }));
                      }
                    });
                }}
                options={
                  isTech ? (hotelList as HotelData[]) : (hotelList?.filter((e) => e.PmsAccess === true) as HotelData[])
                }
                optionLabel="DisplayName"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '150px' }}
              />
            )}
        </li>

        <li className="nav-item d-none d-sm-inline-block"></li>
      </ul>
      <ul className="navbar-nav ml-auto">
        {!isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/' ? (
          userCTX.authenticationInfos.user?.IsAdmin ? (
            <></>
          ) : (
            <Filters></Filters>
          )
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/prevision' ? (
          <PrevisionFilters></PrevisionFilters>
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/pickup' ? (
          <PickupFilters></PickupFilters>
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/maincourante' ? (
          <MainCouranteFilters></MainCouranteFilters>
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/performance' ? (
          <PerformanceFilters></PerformanceFilters>
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/repartition' ? (
          <RepartitionFilters></RepartitionFilters>
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/dashboard' ? (
          <Filters></Filters>
        ) : !isConditionMet && !isMobileOnly && !isTablet && !isMobile && location.pathname === '/planning' ? (
          <PlanningFilters></PlanningFilters>
        ) : (
          <></>
        )}
        <LanguagesDropdown />
        {/*
        <div
          style={{
            borderRadius: '8px',
            width: '8px',
            height: '8px',
            backgroundColor: wsState?.readyState === 1 ? 'green' : 'red',
            position: 'absolute',
            right: '20px',
            top: '15px',

            zIndex: '2',
          }}
        ></div>
*/}
        <UserDropdown></UserDropdown>
        {(window.location.origin.includes('localhost') || window.location.origin.includes('webdispo-dev')) && (
          <MessagesDropdown></MessagesDropdown>
        )}
        {/*}  <li className="nav-item">
          <button type="button" className="nav-link" onClick={handleToggleControlSidebar}>
            <i className="fas fa-th-large" />
          </button>
  </li>*/}
      </ul>
    </nav>
  );
};

export default Header;
