/**
 * Package import
 */
import React, { useEffect, useState } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { shortDateToLocaleString } from '@app/utils/dates';
import fr from 'date-fns/locale/fr';
import moment from 'moment-timezone';
import { InputText } from 'primereact/inputtext';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { DefinedRange } from 'react-date-range';
import { useTranslation } from 'react-i18next';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

interface props {
  data: { DateStart: Date | string | number; DateEnd: Date | string | number };
  numberOfMonthtoDisplay?: number;
  width?: string;
  updateParentFilter: (start: number | string | Date, end: number | string | Date) => void;
  shouldClose: boolean;
}

const CalendarSelector: React.FC<props> = (props) => {
  const UserCtx = useUser();
  const [visible, setVisible] = useState(false);

  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [t] = useTranslation();
  const handleOnChange = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    setState([{ startDate: selection.startDate, endDate: selection.endDate, key: 'selection' }]);
    props.updateParentFilter(selection.startDate as Date, selection.endDate as Date);
  };

  useEffect(() => {
    if (props.shouldClose) {
      setVisible(false);
    }
  }, [props.shouldClose]);
  return (
    <div>
      <InputText
        onClick={() => setVisible(true)}
        // Appeler handleBlur lorsque l'InputText perd le focus
        style={{ width: props.width }}
        value={
          state.length > 0 && state[0].endDate && state[0].startDate
            ? shortDateToLocaleString(
                state[0].startDate ?? new Date(),
                UserCtx?.authenticationInfos?.user?.Culture ?? 'fr',
              ) +
              ' - ' +
              shortDateToLocaleString(
                state[0].endDate ?? new Date(),
                UserCtx?.authenticationInfos?.user?.Culture ?? 'fr',
              )
            : ''
        }
      ></InputText>
      {visible && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '800px',
            border: '1px solid #ced4da',
            borderRadius: '7.5px',
            position: 'absolute',
            backgroundColor: 'white',
            zIndex: 1,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <DefinedRange
              onChange={(item) => {
                setState([
                  { startDate: item?.selection.startDate, endDate: item?.selection.endDate, key: 'selection' },
                ]);
                props.updateParentFilter(item.selection.startDate as Date, item.selection.endDate as Date);

                setVisible(false);
              }}
              ranges={state}
              staticRanges={[
                {
                  label: t('labels.thisMonthToDate'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().startOf('month').toDate(),
                    endDate: moment(new Date()).toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: t('labels.thisMonth'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().startOf('month').toDate(),
                    endDate: moment().endOf('month').toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: t('labels.thisYearToDate'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().startOf('year').toDate(),
                    endDate: moment(new Date()).toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: t('labels.thisYear'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().startOf('year').toDate(),
                    endDate: moment().endOf('year').toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: t('labels.currentPlusThree'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().startOf('month').toDate(),
                    endDate: moment(new Date().setMonth(new Date().getMonth() + 3)).toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: t('labels.today'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: new Date(),
                    endDate: new Date(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
                {
                  label: t('labels.yesterday'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment(new Date()).subtract(1, 'day').toDate(),
                    endDate: moment(new Date()).subtract(1, 'day').toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },

                {
                  label: t('labels.thisWeek'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().startOf('isoWeek').toDate(), // Lundi de cette semaine
                    endDate: moment().endOf('isoWeek').toDate(), // Dimanche de cette semaine
                  }),
                  isSelected() {
                    return true;
                  },
                },

                {
                  label: t('labels.lastMonth'),
                  hasCustomRendering: false,
                  range: () => ({
                    startDate: moment().subtract(1, 'month').startOf('month').toDate(),
                    endDate: moment().subtract(1, 'month').endOf('month').toDate(),
                  }),
                  isSelected() {
                    return true;
                  },
                },
              ]}
              inputRanges={[]}
            />
            <DateRange
              onChange={handleOnChange}
              months={props.numberOfMonthtoDisplay ?? 2}
              ranges={state}
              direction="horizontal"
              locale={fr}
              showDateDisplay={false}
              calendarFocus="forwards"
            ></DateRange>
          </div>
          <div style={{ justifyContent: 'right', display: 'flex' }}>
            <a
              id="CloseButton"
              style={{ fontSize: '12px', margin: '10px', cursor: 'pointer' }}
              onClick={() => setVisible(false)}
            >
              <u> {t('labels.close')}</u>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default CalendarSelector;
