import { useMemo } from 'react';

import { Duration, isAfter } from 'date-fns';
import { isBefore } from 'date-fns';
import { isSameDay } from 'date-fns';
import { format } from 'date-fns';
import { sub } from 'date-fns';
import moment from 'moment';

/*
    dateFrom/dateTo formatted as YYYY-MM-DD
*/

export function isDateBetweenTwoDates(date: string, dateFrom: string, dateTo: string): boolean {
  const dateParsed = Date.parse(date);
  const fromDateParsed = Date.parse(dateFrom);
  const toDateParsed = Date.parse(dateTo);

  return isAfter(dateParsed, fromDateParsed) && isBefore(dateParsed, toDateParsed);
}

export function isCurrentDateBetweenTwoDates(dateFrom: string, dateTo: string): boolean {
  const currentDateParsed = Date.now();
  const fromDateParsed = Date.parse(dateFrom);
  const toDateParsed = Date.parse(dateTo);

  return (
    (isAfter(currentDateParsed, fromDateParsed) || isSameDay(currentDateParsed, fromDateParsed)) &&
    (isBefore(currentDateParsed, toDateParsed) || isSameDay(currentDateParsed, toDateParsed))
  );
}

export function getDeltaInDays(from: number | Date, to: number | Date): number {
  const dt1 = new Date(to);
  const dt2 = new Date(from);
  const timeDiff = dt1.getTime() - dt2.getTime();
  const dayDiff = timeDiff / (1000 * 3600 * 24);
  return Math.round(dayDiff);
}

export function getDeltaInMinutes(from: Date, to: Date): number {
  const fromTime = from.getTime();
  const toTime = to.getTime();
  const hourDiff = toTime - fromTime; //in ms
  const minDiff = hourDiff / 60 / 1000; //in minutes

  return minDiff;
}
export function getDeltaInSeconds(from: Date, to: Date): number {
  const fromTime = from.getTime();
  const toTime = to.getTime();
  const hourDiff = toTime - fromTime; //in ms
  const minDiff = hourDiff / 1000; //in seconds

  return minDiff;
}

export function subtractYears(date: Date, years: number) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function is18orOlder(dateOfBirth: Date): boolean {
  const dateOfBirthPlus18 = new Date(dateOfBirth.getFullYear() + 18, dateOfBirth.getMonth(), dateOfBirth.getDate());

  return dateOfBirthPlus18.valueOf() <= Date.now();
}

export function is14orOlder(dateOfBirth: Date): boolean {
  const dateOfBirthPlus14 = new Date(dateOfBirth.getFullYear() + 14, dateOfBirth.getMonth(), dateOfBirth.getDate());

  return dateOfBirthPlus14.valueOf() <= Date.now();
}

// Format YYYY-MM-DD sent api to DD/MM/YYYY
export function formatToFrenchString(date: string): string {
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
}

//  DD/MM/YYYY to date
export function frenchDateToDate(date: string): Date {
  return moment(date, 'DD/MM/YYYY').toDate();
}

/**
 * Convert the given {@link Date} to a technical representation as `1:00`.
 *
 * @returns Examples: '9:00', '12:01', '22:22'
 *
 * @remarks
 * This function should be used to display time outside sentences.
 */
export function dateToHmm(date: Date): string {
  return moment(date).format('H:mm').toString();
}

/**
 * Convert a {@link Date} into a string representation as `1h`.
 *
 * @returns Examples: '9h', '12h01', '22h22'
 *
 * @remarks
 * This function should be used to display time inside sentences.
 */
export function dateToH_h_m(date: Date): string {
  const minuteFormat = date.getMinutes() > 0 ? 'mm' : '';
  return moment(date).format(`H[h]${minuteFormat}`).toString();
}

export function dateTo_dd_MM_yyyy(date: Date): string {
  return format(date, 'dd/MM/yyyy');
}

export function dateTo_DD_MM(date: Date): string {
  return format(date, 'dd/MM');
}

export function OnlyMonthDateToLocaleString(dateToFormat: Date, culture: string): string {
  return new Date(dateToFormat).toLocaleDateString(culture, {
    year: 'numeric',
    month: 'short',
  });
}
export function shortOnlyMonthDateToLocaleString(dateToFormat: Date, culture: string): string {
  return new Date(dateToFormat).toLocaleDateString(culture, {
    year: '2-digit',
    month: '2-digit',
  });
}

export function shortDateToLocaleString(dateToFormat: Date, culture: string): string {
  return new Date(dateToFormat).toLocaleDateString(culture, {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
}

export function shortDateToLocaleStringWithoutYear(dateToFormat: Date, culture: string): string {
  return new Date(dateToFormat).toLocaleDateString(culture, {
    month: '2-digit',
    day: '2-digit',
  });
}

export function longDateToLocaleString(dateToFormat: Date, culture: string): string {
  return new Date(dateToFormat).toLocaleDateString(culture, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    weekday: 'long',
  });
}

/**
 * Convert a backend into a string representation of its hours & minutes.
 * @param date A backend trip service hours string: '9:00', '12:01', '22h22'.
 * @returns Examples: '9h' '12h01' '22h22'
 */
export function stringHoursToH_h_m(hours: string): string {
  const parts = hours.split(':');
  const date = new Date();
  date.setHours(Number(parts[0]));
  date.setMinutes(Number(parts[1]));
  return dateToH_h_m(date);
}

export function formatFullDate(date: Date | string): string {
  return capitalizeFirstLetter(moment(date).format('ddd D MMM à HH:mm')).toString();
}

function capitalizeFirstLetter(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export const useMemoizedDatetime = (): string => useMemo(() => new Date().toISOString(), []);

export const MINUTES_IN_SECONDS = 60;

type FirebaseTime = { seconds: number; nanoseconds: number };
export function dateFromFirebaseTime(time: FirebaseTime): Date {
  return new Date(time.seconds * 1000);
}

export function isOlderThan(date: Date, duration: Duration): boolean {
  return isBefore(date, sub(new Date(), duration));
}

export function timestampMsToISOString(time: number): string {
  return new Date(time).toISOString();
}
