import React, { useEffect } from 'react';

import { useUser } from '@app/modules/user/UserContext';
import { useWs } from '@app/modules/ws/wsContext';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute = () => {
  const userCTX = useUser();
  const isLoggedIn = userCTX.authenticationInfos.user;
  const wsCTX = useWs();
  const location = useLocation();

  useEffect(() => {
    if (userCTX.authenticationInfos.selectedHotel && wsCTX.isConnected && wsCTX.isInGroup) {
      wsCTX.SendToGroup(
        JSON.stringify({
          IdGroup: userCTX.authenticationInfos.selectedHotel?.IdHotel,
          sender: userCTX.authenticationInfos.user?.FirstnameLastname,
          avatar: userCTX.authenticationInfos.user?.AvatarUrl,
          connectionID: userCTX.authenticationInfos.connectionID,
          location: location.pathname,
        }),
        userCTX.authenticationInfos,
      );
    }
  }, [location, userCTX.authenticationInfos.selectedHotel]);

  return isLoggedIn ? (
    <div id="root2">
      {' '}
      <Outlet />
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
