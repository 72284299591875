import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const PickupFilters: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const userCTX = useUser();
  const filtersCTX = useFilters();
  const [t] = useTranslation();
  const pickupFilters = filtersCTX.filters.pickupFilters;
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const authData = userCTX.authenticationInfos;
  const hotelList = userCTX.authenticationInfos.listHotel;

  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  useEffect(() => {
    if (isTech && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        pickupFilters: {
          ...prevState.pickupFilters,
          hasBeenModified: true,
          IdHotel: [selectedHotel.IdHotel],
        },
      }));
    }
    if (hotelList?.length === 1 && pickupFilters !== null) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        pickupFilters: {
          ...prevState.pickupFilters,
          ListHotels: hotelList,
          hasBeenModified: true,
        },
      }));
    } else {
      if (selectedHotel) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          pickupFilters: {
            ...prevState.pickupFilters,
            ListHotels: [selectedHotel as HotelRight],
            needReload: true,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelList, isTech, selectedHotel]);
  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  useEffect(() => {
    if (
      userCTX.authenticationInfos.selectedHotel &&
      pickupFilters !== null &&
      userCTX.authenticationInfos.selectedHotel
    ) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        pickupFilters: {
          ...prevState.pickupFilters,
          ListHotels: [userCTX.authenticationInfos.selectedHotel as HotelRight],
          needReload: true,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCTX.authenticationInfos.selectedHotel]);

  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      // e.checked donne l'état actuel de la case à cocher
      // Utilisez cette valeur pour gérer le changement
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '4px',
              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLocaleSet && pickupFilters && isMobile && (
        <Accordion activeIndex={0}>
          <AccordionTab header={t('labels.filters')} tabIndex={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'white',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                color: colors.colorGreyAdminLTE,
                padding: '10px',
              }}
            >
              <div style={{ width: '50%', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <label style={{ margin: 0 }}> {t('header.label.from')}</label>
                  <Calendar
                    inputStyle={{ width: '70%', height: '40px' }}
                    style={{ width: '70%' }}
                    value={new Date(pickupFilters.Date)}
                    showWeek
                    todayButtonClassName={'hidden'}
                    touchUI={isMobile}
                    dateFormat="dd/mm/yy"
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        pickupFilters: {
                          ...prevState.pickupFilters,
                          Date: moment(e.value?.getTime()).toDate(),
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    locale={authData?.language || 'fr'}
                  />
                </div>
              </div>
              <div style={{ width: '50%', flexDirection: 'column', justifyContent: 'center' }}>
                <div
                  style={{
                    display: 'flex',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}
                >
                  {!isTech && (
                    <MultiSelect
                      disabled={pickupFilters.ListHotels.length === 1}
                      panelHeaderTemplate={customHeader}
                      value={pickupFilters.ListHotels.sort((a, b) => {
                        if (a.Name < b.Name) {
                          return -1;
                        }
                        if (a.Name > b.Name) {
                          return 1;
                        }
                        return 0;
                      })}
                      onChange={(e) => {
                        filtersCTX.setFilters((prevState) => ({
                          ...prevState,
                          mainCouranteFilters: {
                            ...prevState.mainCouranteFilters,
                            ListHotels: e.value,
                            hasBeenModified: true,
                          },
                        }));
                      }}
                      options={
                        hotelList?.sort((a, b) => {
                          if (a.Name < b.Name) {
                            return -1;
                          }
                          if (a.Name > b.Name) {
                            return 1;
                          }
                          return 0;
                        }) ?? []
                      }
                      optionLabel="Name"
                      placeholder={t('label.placeholder.choice') ?? ''}
                      style={{ width: '180px', justifyContent: 'center' }}
                    />
                  )}
                  {isTech && (
                    <Dropdown
                      value={selectedHotel}
                      onChange={async (e) => {
                        userCTX.setUser((prevState) => ({
                          ...prevState,
                          selectedHotel: e.value ?? '',
                        }));
                        storage.setParam('selectedHotel', JSON.stringify(e.value));
                      }}
                      options={hotelList as HotelData[]}
                      optionLabel="Name"
                      filter
                      placeholder={t('label.placeholder.choice') ?? ''}
                      className="w-full md:w-14rem"
                      style={{ whiteSpace: 'nowrap', maxWidth: '180px', height: '40px' }}
                    />
                  )}
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {isLocaleSet && pickupFilters && !isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',

            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <label style={{ margin: 0 }}> {t('header.label.from')}</label>
            <Calendar
              inputStyle={{ width: '100px' }}
              value={new Date(pickupFilters.Date)}
              showWeek
              todayButtonClassName={'hidden'}
              touchUI={isMobile}
              style={{ marginLeft: '10px', marginRight: '10px' }}
              dateFormat="dd/mm/yy"
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  pickupFilters: {
                    ...prevState.pickupFilters,
                    Date: moment(e.value?.getTime()).toDate(),
                    hasBeenModified: true,
                  },
                }));
              }}
              locale={authData?.language || 'fr'}
            />{' '}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                id="FilterHotel"
                disabled={hotelList?.length === 1}
                value={pickupFilters.ListHotels.sort((a, b) => {
                  if (a.Name < b.Name) {
                    return -1;
                  }
                  if (a.Name > b.Name) {
                    return 1;
                  }
                  return 0;
                })}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    pickupFilters: {
                      ...prevState.pickupFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ marginRight: '10px', marginLeft: '10px', width: '190px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '200px', marginRight: '10px', marginLeft: '10px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Button
              id="FilterButton"
              disabled={isTech ? false : pickupFilters.ListHotels.length <= 0}
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '4px',
                backgroundColor: filtersCTX.filters.pickupFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',

                textAlign: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  pickupFilters: {
                    ...prevState.pickupFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));

                /*  dispatch(
                  getMainCouranteData({ mainCouranteFilters: mainCouranteFilters, params: { isTech, selectedHotel } }),
                );*/
              }}
              icon="pi pi-refresh"
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PickupFilters;
