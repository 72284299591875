import React, { createContext, ReactNode, useContext, useState } from 'react';

import moment from 'moment-timezone';

// Define the type for your params
export type Filters = {
  dashboardFilters: DashBoardFilters;
  mainCouranteFilters: MainCouranteFilters;
  previsionFilters: PrevisionFilters;
  planningFilters: PlanningFilters;
  pickupFilters: PickupFilters;
  performanceFilters: PerformanceFilters;
  repartitionFilters: RepartitionFilters;
};

// Define the interface for ParamsContextState
interface FiltersContextState {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
}

// Create a context to hold your params with default values
export const FiltersContext = createContext<FiltersContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type FiltersProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const FiltersProvider: React.FC<FiltersProviderProps> = (props) => {
  const [filters, setFiltersState] = useState<Filters>({
    planningFilters: {
      DateStart: new Date().getTime(),
      DatePointStart: new Date().getTime(),
      DatePointEnd: new Date().getTime(),
      DateEnd: moment(Date.now()).add(18, 'd').toDate().getTime(),
      IdHotel: 0,
      SelectedHotels: null,
      TypeToDisplay: null,
      CurrentSort: 1,
      ForceOneDay: false,
      IsCollapse: false,
      needReload: false,
      dayToDisplay: 21,
      hasBeenModified: false,
      showDeparture: false,
    },
    dashboardFilters: {
      TypeFilters: ['TO', 'PM', 'RevPar', 'Bookings', 'IF', 'ALOS', 'RevPor', 'Occupied'],
      DateStart: new Date().getTime(),
      DateEnd: moment(Date.now()).add(20, 'd').toDate().getTime(),
      IdHotel: null,
      IncludeAllotments: true,
      PickupDate: new Date().getTime(),
      PickupDifferences: true,
      DisplayMode: 1,
      needReload: false,
      hasBeenModified: false,
    },
    mainCouranteFilters: {
      DateStart: moment(Date.now()).subtract(1, 'month').subtract(1, 'day').toDate().getTime(),
      DateEnd: moment(new Date()).subtract(1, 'day').toDate().getTime(),
      ListHotels: [],
      IdHotel: [0],
      CategoryType: 0,
      IncludingTaxes: false,
      needReload: false,
      hasBeenModified: false,
    },
    previsionFilters: {
      DateStart: new Date().getTime(),
      DateEnd: moment(Date.now()).add(20, 'd').toDate().getTime(),
      ListHotels: [],
      IdHotel: [],
      ForecastType: 0,
      IncludingOptions: true,
      IncludingTaxes: true,
      needReload: false,
      hasBeenModified: false,
    },
    pickupFilters: {
      Date: new Date().getTime(),
      IdHotel: [],
      ListHotels: [],
      needReload: false,
      hasBeenModified: false,
    },
    performanceFilters: {
      DateStart: new Date(),
      DateEnd: new Date(),
      IdHotel: [],
      ListHotels: [],
      Categories: [],
      GroupBy: -1,
      YearToCompare: 0,
      hasBeenModified: false,
      needReload: false,
    },
    repartitionFilters: {
      DateStart: new Date(),
      DateEnd: new Date(),
      IdHotel: [],
      Category: null,
      AggregationType: null,
      Max: 0,
      KpiAreProrata: false,
      hasBeenModified: false,
      ListHotels: [],
      needReload: false,
      GroupBy: 0,
    },
  });
  const setFilters: React.Dispatch<React.SetStateAction<Filters>> = (value) => {
    setFiltersState(value);
  };
  return <FiltersContext.Provider value={{ filters, setFilters }}>{props.children}</FiltersContext.Provider>;
};

// Create a custom hook to access the params
export function useFilters(): FiltersContextState {
  const filtersContext = useContext(FiltersContext);
  if (filtersContext === undefined) {
    throw new Error('useFilters must be used within a FilterProvider');
  }
  return filtersContext;
}
