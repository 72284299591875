import { useEffect, useState } from 'react';

export const useMediaQuery = (query: any) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setMatches(mediaQueryList.matches);

    // Initial check
    setMatches(mediaQueryList.matches);

    // Écouter les changements de media queries
    mediaQueryList.addEventListener('change', documentChangeHandler);

    // Nettoyer l'écouteur lors du démontage
    return () => {
      mediaQueryList.removeEventListener('change', documentChangeHandler);
    };
  }, [query]);

  return matches;
};
