import React, { createContext, ReactNode, useContext, useState } from 'react';

import { GridDetailledData } from '@app/types/types';
import { getLogger } from 'loglevel';
import moment from 'moment-timezone';

import axios from '@services/axios/Axios';

// Define the type for your params

interface DashboardServiceRequestParams {
  dashboardFilters?: DashBoardFilters;
  params: TechAndHotelParams;
  IdHotel?: number;
}
// Define the interface for ParamsContextState
interface DashboardContextState {
  KPIS: KPISData | null;
  GridData: GridData | null;
  GridDetail: { [idHotel: string]: GridDetailledData } | null;
  AdminStat: AdminStat[] | null;
  TodayNewBooking: GridData | null;
  setGridData: (GridData: GridData | null) => void;
  getGridData: (params: DashboardServiceRequestParams) => Promise<void | undefined>;
  setGridDetail: (gridDetail: { [idHotel: string]: GridDetailledData } | null) => void;
  getGridDetail: (params: DashboardServiceRequestParams) => Promise<void | undefined>;
  getKPIS: (params: DashboardServiceRequestParams) => Promise<void | undefined>;
  setKPIS: (KPISData: KPISData | null) => void;
  getTodayNewBooking: (params: DashboardServiceRequestParams) => Promise<void | undefined>;
  setTodayNewBooking: (GridData: GridData | null) => void;
  getAdminStat: (params: DashboardServiceRequestParams) => Promise<void | undefined>;
  setAdminStat: (AdminStat: AdminStat[] | null) => void;
}

// Create a context to hold your params with default values
export const DashboardContext = createContext<DashboardContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type DashboardProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const DashboardProvider: React.FC<DashboardProviderProps> = (props) => {
  const [GridData, setGridData] = useState<GridData | null>(null);
  const [GridDetail, setGridDetail] = useState<{ [idHotel: string]: GridDetailledData } | null>(null);
  const [KPIS, setKPIS] = useState<KPISData | null>(null);
  const [AdminStat, setAdminStat] = useState<AdminStat[] | null>(null);
  const [TodayNewBooking, setTodayNewBooking] = useState<GridData | null>(null);

  const getGridData = async (params: DashboardServiceRequestParams) => {
    try {
      const filters = { ...params.dashboardFilters };
      filters.DateEnd = moment(filters.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;

      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      if (filters.PickupDate) {
        filters.PickupDate = moment(new Date(filters.PickupDate)).format('YYYY-MM-DD');
      }
      if (params.params.selectedHotel) {
        filters.IdHotel = params.params.selectedHotel.IdHotel;
      } else {
        delete filters.IdHotel;
      }

      const urlToPass = 'GroupDailyTotals';
      const response = await axios.post('/Dashboard/' + urlToPass, filters, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setGridData(data.Data as GridData);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getGridDetail = async (params: DashboardServiceRequestParams) => {
    try {
      const filters = { ...params.dashboardFilters };

      filters.IdHotel = params.params.IdHotel;

      filters.DateEnd = moment(filters.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;

      filters.PickupDate = moment(filters.PickupDate).format('YYYY-MM-DD') as string;

      const urlToPass = 'HotelDailyData';
      const response = await axios.post('/Dashboard/' + urlToPass, filters, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;

      const responseData: GridDetailledData = data.Data as GridDetailledData;

      setGridDetail({ ...GridDetail, [filters.IdHotel as number]: responseData });
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getKPIS = async (params: DashboardServiceRequestParams) => {
    try {
      if (
        params.params.isTech === true &&
        (params.params.selectedHotel === null || params.params.selectedHotel === undefined)
      ) {
        return;
      }

      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :
      const urlToPass = 'kpi';
      const response = await axios.post('/Dashboard/' + urlToPass, params.params.params, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      const responseData: KPISData = data.Data as KPISData;
      setKPIS(responseData);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getTodayNewBooking = async (params: DashboardServiceRequestParams) => {
    try {
      const filters = { ...params.dashboardFilters };
      if (!params.params.selectedHotel && params.params.isTech) return;

      filters.DateEnd = moment(filters.DateEnd).format('YYYY-MM-DD') as string;
      filters.DateStart = moment(filters.DateStart).format('YYYY-MM-DD') as string;
      if (params.params.isTech === true && params.params.selectedHotel === null) {
        return;
      } else if (params.params.isTech === true) {
        //    filters.IdHotel = params.params.selectedHotel?.IdHotel;
      }

      filters.DateEnd = moment(Date.now()).add(20, 'day').format('YYYY-MM-DD');
      filters.DateStart = moment(Date.now()).format('YYYY-MM-DD');

      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const urlToPass = 'TodayNewBookings';
      const response = await axios.post('/Dashboard/' + urlToPass, filters, {});

      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }

      const data: MedialogResponse = await response.data;
      setTodayNewBooking(data.Data as GridData);
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  const getAdminStat = async (params: DashboardServiceRequestParams) => {
    const isTech = params.params.isTech;

    try {
      if (isTech === true) {
        const urlToPass = 'PmsStat';
        const response = await axios.post('/Admin/' + urlToPass);

        if (response.status !== 200) {
          throw new Error('Erreur : ');
        }

        const data: MedialogResponse = await response.data;

        setAdminStat(data.Data as AdminStat[]);
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        GridData,
        getGridData,
        AdminStat,
        GridDetail,
        getGridDetail,
        getKPIS,
        KPIS,
        TodayNewBooking,
        getTodayNewBooking,
        getAdminStat,
        setAdminStat,
        setGridData,
        setGridDetail,
        setKPIS,
        setTodayNewBooking,
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

// Create a custom hook to access the params
export function useDashboard(): DashboardContextState {
  const dashboardContext = useContext(DashboardContext);
  if (dashboardContext === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return dashboardContext;
}
