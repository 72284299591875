import React, { useEffect, useState } from 'react';

import { config } from '@app/utils/config';
import { useLogger } from '@modules/logger/LoggerContext';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createApi } from 'unsplash-js';

import Logo from '@assets/logo.png';
import Logo2 from '@assets/LOGO2.svg';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useUser } from '../user/UserContext';

import '@styles/loginStyle.css';
import { v7 as uuidv7 } from 'uuid';

const Login: React.FC = () => {
  const [backImage, setBackImage] = useState('');

  const [unsplashResponse, setUnsplashResponse] = useState<any>({});

  const unsplash = createApi({
    accessKey: 'h7jwdGrFXr-Jeq7KajhIyXk-bMQmhHZpOJujzrUhQfM',
  });

  // unsplashApi.get('/photos/random', { params: { query } }).then((e) => setBackImage(e.data));

  useEffect(() => {
    unsplash.photos.getRandom({ query: 'hotel' }).then((e: any) => {
      setBackImage(e?.response?.urls?.regular);
      setUnsplashResponse(e.response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [t] = useTranslation();
  const navigate = useNavigate();
  const userCTX = useUser();
  const localUser = localStorage.getItem('user');
  useEffect(() => {
    if (localUser !== 'null' && localUser) {
      userCTX.refreshToken();
      userCTX.setUser((prevState) => ({
        ...prevState,
        user: JSON.parse(localUser ?? ''),
        refreshIntervalNumber: setInterval(() => {
          userCTX.refreshToken();
        }, 1800000) as unknown as number,
      }));
    }
  }, [localUser, userCTX]);

  const login = async (email: string, password: string) => {
    try {
      const loginParam: AuthParams = { IsTech: isTech, Login: email, Password: password };
      userCTX
        .logonAPI(loginParam)
        .then((res: ResType) => {
          const loggedData = res;
          if (loggedData === null || loggedData === undefined || loggedData.User === undefined) {
            throw res;
          }
          //  dispatch(setUser(loggedData.User));
          userCTX.setUser({
            user: loggedData.User,
            error: '',
            token: loggedData.Token,
            ExpiresUtcAt: loggedData.ExpiresUtcAt,
            listHotel: loggedData.Hotels,
            language: loggedData?.User?.Culture?.split('-')[0],
            wsToTreat: null,
            currentAxiosRule: null,
            userMenu: null,
            connectionID: uuidv7(),
            refreshIntervalNumber: setInterval(() => {
              userCTX.refreshToken();
            }, 1800000) as unknown as number,
          });
          navigate('/');

          // Gérer le succès de la connexion ici
        })
        .catch((error) => {
          // Gérer les erreurs de connexion ici
          setInvalidPassword(true);
          toast.error(error?.message?.replace('1000', ' Identifiant ou mot de passe incorrect') ?? error);
        });
    } catch (e) {
      toast.error('Failed');
    }
  };

  const apiLogger = useLogger('api');
  const logger = useLogger('web');
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    login(data.get('email')?.toString() || '', data.get('password')?.toString() || '');
  };
  const [clickCount, setClickCount] = useState(0);
  const [isTech, setIsTech] = useState(false);

  const [invalidPassword, setInvalidPassword] = useState(false);
  const handleImageClick = () => {
    // Increment the click count when the image is clicked
    setClickCount(clickCount + 1);
    // Check if the click count is greater than or equal to 5
    if (clickCount >= 5) {
      // Set the mode to a different value
      setIsTech(true);
    }
  };
  const handleTechImageClick = () => {
    setClickCount(0);
    setIsTech(false);
  };

  useEffect(() => {
    // declare the data fetching function
    // exeple d'appel
    /* logger.info('Un message informatif');
      apiLogger.info('Un message informatif');
      apiLogger.warn('Un warn informatif', 'information supplémentaire');
      apiLogger.error(new Error('Une erreur qui aurait été thrown'), 'information supplémentaire');
  */
    // call the function
  }, [logger, apiLogger]);

  return (
    <>
      {isTech === false ? (
        <Grid container component="main" sx={{ height: 'calc(100vh)' }}>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage: `url(${backImage})`,

              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <label style={{ position: 'absolute', bottom: 0, fontSize: '9pt' }}>
            Photo by{' '}
            <a
              style={{ color: 'black', textDecoration: 'underline' }}
              href={unsplashResponse?.user?.links?.html + '?utm_source=Webdispo&utm_medium=referral'}
            >
              {unsplashResponse?.user?.name}
            </a>{' '}
            on{' '}
            <a
              style={{ color: 'black', textDecoration: 'underline' }}
              href="https://unsplash.com/?utm_source=Webdispo&utm_medium=referral"
            >
              Unsplash
            </a>
          </label>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img src={Logo2} style={{ width: '400px' }} onClick={handleImageClick} />

              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('login.label.username')}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  onChange={() => setInvalidPassword(false)}
                  name="password"
                  label={t('login.label.password')}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Grid container>
                  <Grid item xs>
                    {invalidPassword ? (
                      <Typography
                        component="p"
                        variant="body2"
                        style={{
                          color: 'red',
                          justifyContent: 'center',
                          display: 'flex',
                        }}
                      >
                        {t('login.label.invalid.password')}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>

                <Button id="login_button" type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {t('login.label.validate')}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link
                      to={config.API_URL + '/User/ForgotPassword'}
                      style={{ justifyContent: 'center', display: 'flex' }}
                    >
                      {t('login.label.forgot')}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <div className="loginBody">
              <div className="login-container">
                <img src={Logo} style={{ width: '300px' }} onClick={handleTechImageClick} />
                <h2>Technicien</h2>{' '}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t('login.label.username')}
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('login.label.password')}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  {t('login.label.validate')}
                </Button>
              </div>

              <div className="light x1" />
              <div className="light x2" />
              <div className="light x3" />
              <div className="light x4" />
              <div className="light x5" />
              <div className="light x6" />
              <div className="light x7" />
              <div className="light x8" />
              <div className="light x9" />
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default Login;
