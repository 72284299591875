import { useEffect, useState } from 'react';

import MainCourantteFilter from '@app/components/Common/filter/MainCouranteFilter';
import MainCouranteDataTable from '@app/components/Desktop/Table/mainCouranteDatatable/mainCouranteDatatable';
import { MainCouranteProvider } from '@app/modules/MainCourante/MainCouranteContext';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const MainCourante: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);

  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        <div className="row">
          <div className="col-lg-12 col-12">
            {(isMobile || isTablet || isConditionMet) && (
              <div
                className={`small-box`}
                style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
              >
                <div className="inner">
                  <MainCourantteFilter></MainCourantteFilter>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <MainCouranteProvider>
              <MainCouranteDataTable></MainCouranteDataTable>
            </MainCouranteProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainCourante;
