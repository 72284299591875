import { createContext, ReactNode, useContext, useState } from 'react';

import BaseForm from '@app/components/Common/popup/BaseForm';
import { Message } from '@app/types/types';
import moment from 'moment-timezone';

export type MessageWindowType = {
  listOfMessageWindowDisplayed: {
    id: string;
    actions: any;
    component: React.ComponentType<any>;
    x?: number | string;
    y?: number | string;
    data: any;
    translatedTitle: string;
  }[];
};

interface MessagesContextState {
  messageWindow: MessageWindowType;
  messages: { [sender: string]: Message[] } | null;
  updateMessages: (newMessage: Message) => void;
  updateMessagesSended: (newMessage: Message) => void;

  setMessageWindow: React.Dispatch<React.SetStateAction<MessageWindowType>>;
  hideMessageWindow: (id: string) => void; // Add this
}

export const MessageContext = createContext<MessagesContextState | undefined>(undefined);

// Fournisseur de contexte des messages
// Define the props type for the ParamsProvider component
type WsProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const MessageProvider: React.FC<WsProviderProps> = (props) => {
  const [messages, setMessages] = useState<{ [sender: string]: Message[] }>({});
  const [messageWindow, setMessageWindow] = useState<MessageWindowType>({ listOfMessageWindowDisplayed: [] });

  const hideMessageWindow = (id: string) => {
    setMessageWindow((prevWindow) => ({
      ...prevWindow,
      listOfMessageWindowDisplayed: prevWindow.listOfMessageWindowDisplayed.filter((form) => form.id !== id),
    }));
  };

  const updateMessages = (newMessage: Message) => {
    newMessage.date = moment(Date.now()).format('DD/MM/YY HH:MM');

    setMessages((prevMessages) => {
      const sender = newMessage.SenderId;
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[sender]) {
        updatedMessages[sender] = [];
      }
      updatedMessages[sender].push(newMessage);
      return updatedMessages;
    });
  };

  const updateMessagesSended = (newMessage: Message) => {
    newMessage.date = moment(Date.now()).format('DD/MM/YY HH:MM');

    setMessages((prevMessages) => {
      const sender = newMessage.UserId;
      const updatedMessages = { ...prevMessages };
      if (!updatedMessages[sender]) {
        updatedMessages[sender] = [];
      }
      updatedMessages[sender].push(newMessage);
      return updatedMessages;
    });
  };

  return (
    <MessageContext.Provider
      value={{ messages, updateMessages, messageWindow, hideMessageWindow, setMessageWindow, updateMessagesSended }}
    >
      {messageWindow.listOfMessageWindowDisplayed.map((form, index) => {
        return (
          <BaseForm
            id={form.id}
            data={form.data}
            translatedTitle={form.translatedTitle ?? ''}
            key={index}
            component={form.component}
            actions={form.actions}
            draggable={false}
            fullWidth={false}
            fullHeight={false}
            x={form.x}
            y={form.y}
            zIndex={999}
          />
        );
      })}
      {props.children}
    </MessageContext.Provider>
  );
};

// Create a custom hook to access the params
export function useMessages(): MessagesContextState {
  const wsContext = useContext(MessageContext);
  if (wsContext === undefined) {
    throw new Error('useMessages must be used within a MessageProvider');
  }
  return wsContext;
}
