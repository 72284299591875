import React, { useEffect, useState } from 'react';

import { IMenuItem } from '@app/modules/main/menu-sidebar/MenuSidebar';
import { useTranslation } from 'react-i18next';
import { Location, useLocation, useNavigate } from 'react-router-dom';

const MenuItem = ({ menuItem }: { menuItem: IMenuItem }) => {
  const [t] = useTranslation();
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleMainMenuAction = () => {
    if (isExpandable) {
      setIsMenuExtended(!isMenuExtended);
      return;
    }
    if (menuItem.downloadPath) {
      const link = document.createElement('a');
      link.href = menuItem.downloadPath;
      link.download = menuItem.downloadPath;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }
    if (menuItem.type === 'installation') {
      if (menuItem.level !== undefined) {
        navigate(
          'installation/' +
            menuItem.PermissionId +
            '/' +
            menuItem.CanAccess +
            '/' +
            menuItem.code +
            '/' +
            menuItem.level,
        );
      } else {
        navigate('installation/' + menuItem.PermissionId + '/' + menuItem.CanAccess + '/' + menuItem.code);
      }
      return;
    }
    navigate(menuItem.path ? menuItem.path : '/');
  };

  const calculateIsActive = (url: Location) => {
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable && menuItem && menuItem.children) {
      menuItem.children.forEach((item) => {
        if (item.path?.includes(url.pathname)) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (menuItem.path === url.pathname) {
      setIsMainActive(true);
    }
  };

  useEffect(() => {
    if (location) {
      calculateIsActive(location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false);
    } else {
      setIsMenuExtended(true);
    }
  }, [isMainActive, isOneOfChildrenActive]);

  useEffect(() => {
    setIsExpandable(Boolean(menuItem && menuItem.children && menuItem.children.length > 0));
  }, [menuItem]);

  return (
    <li className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
      <a
        className={`nav-link${isMainActive || isOneOfChildrenActive ? ' active' : ''}`}
        role="link"
        onClick={handleMainMenuAction}
        style={{ cursor: 'pointer' }}
      >
        <i className={`${menuItem.icon}`} />
        <span className="menu-item-text">{t(menuItem.name)}</span>
        {isExpandable ? <i className="right fas fa-angle-left" /> : null}
      </a>

      {isExpandable && menuItem.children && (
        <ul className="nav nav-treeview">
          {menuItem.children.map((childItem) => (
            <MenuItem key={childItem.name} menuItem={childItem} />
          ))}
        </ul>
      )}
    </li>
  );
};

export default MenuItem;
