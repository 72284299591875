import React, { createContext, ReactNode, useContext, useState } from 'react';

import { AnalyticsBookingPickupData, AnalyticsPerformance, AnalyticsRepartition } from '@app/types/types';
import { getLogger } from 'loglevel';
import moment from 'moment';

import axios from '@services/axios/Axios';

import { useUser } from '../user/UserContext';

// Define the type for your params

// Define the interface for ParamsContextState
interface AnalyticsContextState {
  AnalyticsBookingPickupData: AnalyticsBookingPickupData | null;
  setAnalyticsBookingPickupData: (AnalyticsBookingPickupData: AnalyticsBookingPickupData | null) => void;
  getAnalyticsBookingPickupData: (Date: string, IdHotel: number[]) => Promise<void | undefined>;
  AnalyticsPerformance: AnalyticsPerformance | null;
  setAnalyticsPerformance: (AnalyticsPerformance: AnalyticsPerformance | null) => void;
  getAnalyticsPerformance: (params: PerformanceFilters) => Promise<AnalyticsPerformance | undefined>;
  AnalyticsRepartition: AnalyticsRepartition[] | null;
  setAnalyticsRepartition: (AnalyticsRepartition: AnalyticsRepartition[] | null) => void;
  getAnalyticsRepartition: (params: RepartitionFilters) => Promise<AnalyticsRepartition[] | undefined>;
}

// Create a context to hold your params with default values
export const AnalyticsContext = createContext<AnalyticsContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type AnalyticsProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const AnalytiqueProvider: React.FC<AnalyticsProviderProps> = (props) => {
  const userCTX = useUser();
  const [AnalyticsBookingPickupData, setAnalyticsBookingPickupData] = useState<AnalyticsBookingPickupData | null>(null);
  const [AnalyticsPerformance, setAnalyticsPerformance] = useState<AnalyticsPerformance | null>(null);
  const [AnalyticsRepartition, setAnalyticsRepartition] = useState<AnalyticsRepartition[] | null>(null);

  const getAnalyticsBookingPickupData = async (Date: string, IdHotel: number[]) => {
    try {
      const response = await axios.post('Analytics/Bookings/Pickup', { Date, IdHotel }, {});

      const data: MedialogResponse = await response.data;
      setAnalyticsBookingPickupData(data.Data as AnalyticsBookingPickupData);
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getAnalyticsPerformance = async (params: PerformanceFilters) => {
    try {
      const response = await axios.post(
        'Analytics/Performances',
        {
          DateStart: moment(params.DateStart).format('YYYY-MM-DD'),
          DateEnd: moment(params.DateEnd).format('YYYY-MM-DD'),
          IdHotel: userCTX.authenticationInfos.user?.IsAdmin
            ? [userCTX.authenticationInfos.selectedHotel?.IdHotel]
            : params.ListHotels.map((e) => e.IdHotel),
          Categories: params.Categories.map((e) => e.code),
          GroupBy: params.GroupBy,
          YearToCompare: params.YearToCompare,
        },
        {},
      );

      const data: MedialogResponse = await response.data;
      setAnalyticsPerformance(data.Data as AnalyticsPerformance);
      return data.Data as AnalyticsPerformance;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const getAnalyticsRepartition = async (params: RepartitionFilters) => {
    try {
      if (params.AggregationType?.code) {
        const response = await axios.post(
          'Analytics/Repartition',
          {
            DateStart: moment(params.DateStart).format('YYYY-MM-DD'),
            DateEnd: moment(params.DateEnd).format('YYYY-MM-DD'),
            IdHotel: userCTX.authenticationInfos.user?.IsAdmin
              ? [userCTX.authenticationInfos.selectedHotel?.IdHotel]
              : params.ListHotels.map((e) => e.IdHotel),
            Category: params?.Category?.code,
            AggregationType: params.AggregationType?.code,
            KpiAreProrata: params.KpiAreProrata,
            GroupBy: params.GroupBy,
          },
          {},
        );

        const data: MedialogResponse = await response.data;
        setAnalyticsRepartition(data.Data as AnalyticsRepartition[]);
        return data.Data as AnalyticsRepartition[];
      }
    } catch (error) {
      getLogger('web').error(error);
    }
  };
  return (
    <AnalyticsContext.Provider
      value={{
        AnalyticsBookingPickupData,
        getAnalyticsBookingPickupData,
        setAnalyticsBookingPickupData,
        AnalyticsPerformance,
        getAnalyticsPerformance,
        setAnalyticsPerformance,
        AnalyticsRepartition,
        getAnalyticsRepartition,
        setAnalyticsRepartition,
      }}
    >
      {props.children}
    </AnalyticsContext.Provider>
  );
};

// Create a custom hook to access the params
export function useAnalytics(): AnalyticsContextState {
  const analyticsContext = useContext(AnalyticsContext);
  if (analyticsContext === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }
  return analyticsContext;
}
