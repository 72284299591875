import { useEffect, useState } from 'react';

import PickupLineChart from '@app/components/Common/charts/PickupLineChart';
import PickupFilters from '@app/components/Common/filter/PickupFilters';
import { AnalytiqueProvider } from '@app/modules/Analytics/AnalyticsContext';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import { isMobile, isTablet, useMobileOrientation } from 'react-device-detect';

const Pickup: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);

  return (
    <section className="content">
      <div className="container-fluid" style={{ paddingTop: '20px' }}>
        <div className="row">
          <div className="col-lg-12 col-12">
            {(isMobile || isTablet || isConditionMet) && (
              <div
                className={`small-box`}
                style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
              >
                {' '}
                <div className="inner">
                  <PickupFilters></PickupFilters>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-12">
            <AnalytiqueProvider>
              <PickupLineChart></PickupLineChart>
            </AnalytiqueProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pickup;
